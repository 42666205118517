<template>
    <div>
      <dashboard-page-title :showFilter="false" :showSearch="false" :showExportation="false" :showAddBtn="false">اضافة نوع شاحنة جديد</dashboard-page-title>
      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <b-form class="position-relative form-top w-50 " @submit.prevent="handleSubmit(addType)">
          <b-tabs content-class="mt-3">
            <b-tab v-for="(lang, key) in translations" :key="key" :title="lang.lang" >
              <input-form :label="' الاسم -' + lang.lang " placeholder="الاسم" v-model="lang.name" :disabled="routeName === 'viewVehicleMake'" :validate="lang.is_default ? 'required':'' " />
              <input name="translations[lang]" :value="lang.code" type="hidden"/>
            </b-tab>
          </b-tabs>
          <cropper-images
              label="رفع الصورة الرئيسية"
              nameOfImage="image.jpg"
              @cropper-save="saveMasterImage"
              :progressLoading="loadingLogo"
              :showProgress="false"
              :multi="false"
              :imageUrl="vehicleMake.logo"
          />
          <b-button v-if="routeName !== 'viewVehicleMake'" variant="primary" :disabled="invalid" type="submit"><span  class="font-size-18 px-5">اضافة</span></b-button>
        </b-form>
      </ValidationObserver>
    </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import transportationServices from '@/modules/transportation/services/transportation'
import getLanguages from '@/mixins/getLanguages'
import globalFunctionLang from '@/mixins/globalAdd'
// const formData = new FormData()
export default {
  name: 'addVehicleType',
  mixins: [getLanguages, globalFunctionLang],
  data () {
    return {
      loadingLogo: 0,
      vehicleMake: {
        logo: ''
      },
      routeName: this.$route.name,
      translatedColumns: ['name'],
      translations: []
    }
  },
  methods: {
    getTypeData () {
      this.translations = []
      transportationServices.getVehicleMake(this.$route.params.id).then(res => {
        // this.translations.name = res.data.translations.name
        this.vehicleMake.logo = res.data.data.image
        this.translations = this.handleResponseTranselation(this.translatedColumns, res.data.data.translations)
      })
    },
    addType () {
      if (this.$route.name === 'editVehicleMake') {
        this.editType()
      } else {
        const finalTranslations = this.translations.filter(translation => this.translatedColumns.some(column => translation[column]))
        transportationServices.addVehicleMake({
          ...this.vehicleMake,
          translations: finalTranslations
        }).then(res => {
          core.showSnackbar('success', res.data.message)
          this.$router.push({ name: 'vehiclesMake' })
        })
      }
    },
    saveMasterImage (file) {
      const formData = new FormData()
      formData.append('directory', 'VehicleMake')
      formData.append('image_name', file.imageInfo.name)
      formData.append('image', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.vehicleMake.logo = res.data
        this.showSuccessUploadFile()
      })
    },
    editType () {
      transportationServices.editVehicleMake(this.$route.params.id, this.vehicleMake).then(() => {
        core.showSnackbar('success', 'تم تعديل بنجاح')
        this.$router.push({ name: 'vehiclesType' })
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    // function handleResponseTranselation (key, dynamicTranselation, lang) {
    //   const translations = []
    //   lang.forEach(lang => {
    //     dynamicTranselation.forEach(dataFromBack => {
    //       // Lw al Trgma mogoda
    //       if (dataFromBack.lang === lang.code) {
    //         translations.push(dataFromBack)
    //       } else {
    //         // Lw al Trgma m4 mogoda h3ml create ll object b al keys btaty bs fadya
    //         const obj = key.reduce((accumulator, value) => {
    //           return { ...accumulator, [value]: '' }
    //         }, {})
    //         translations.push({ ...obj, lang: lang.code })
    //       }
    //     })
    //   })
    //   return translations
    // }
    // var result = handleResponseTranselation(['name', 'title'], [{ lang: 'en', name: 'اى حاجه', title: 'اى عنوان' }], [{ code: 'en' }, { code: 'ar' }, { code: 'fr' }])
    // console.log('result => ', result)
    if (this.$route.params.id) {
      this.getTypeData()
    } else {
      this.getAllLanguages.forEach(data => {
        const translation = {
          lang: data.code,
          ...data
        }
        this.translatedColumns.forEach(data => {
          translation[data] = null
        })
        this.translations.push(translation)
      })
    }
  }
}
</script>
